<template>
  <action-menu-wrapper
    data-cy="stips-item-menu"
    :disabled="cantUpdateOrDelete"
    :items="menuItems"
    teleport
    :class="{ 'text-disabled-medium': cantUpdateOrDelete }"
    :offset="{ right: -43, top: 43 }"
  />
  <document-template-modal
    v-if="showEditModal"
    :template-id="model.id"
    mode="edit"
    @close="showEditModal = false"
  />
  <document-template-delete-modal
    v-if="showDeleteModal"
    :template="model"
    @close="showDeleteModal = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePermissions } from "@/hooks/auth";
import { Ability, PermissionSubject } from "@/enums/auth";
import compact from "lodash/compact";
import ActionMenuWrapper from "@/components/ui/ActionMenuWrapperV2.vue";
import DocumentTemplateModal from "./DocumentTemplateModal.vue";
import DocumentTemplateDeleteModal from "@/views/profile/components/DocumentTemplateDeleteModal.vue";
import type { DocumentTemplate } from "@/models/documentTemplates";
import type { MenuItem } from "@/components/ui/ActionMenuWrapperV2.vue";

const { model } = defineProps<{
  model: DocumentTemplate;
}>();

const { t } = useI18n();
const { canPerformActionReactive } = usePermissions();

const showEditModal = ref(false);
const showDeleteModal = ref(false);

const [canUpdate, canDelete] = [Ability.update, Ability.delete].map((action) =>
  canPerformActionReactive(PermissionSubject.documentTemplate, action, {
    documentTemplate: model.id
  })
);

const cantUpdateOrDelete = computed(() => !canUpdate.value && !canDelete.value);

const menuItems = computed<MenuItem[]>(() =>
  compact([
    canUpdate.value
      ? {
          handler: () => (showEditModal.value = true),
          label: t("COMMON.EDIT")
        }
      : undefined,
    canDelete.value
      ? {
          handler: () => (showDeleteModal.value = true),
          label: t("COMMON.DELETE")
        }
      : undefined
  ])
);
</script>
